<template>
    <div class="ins-TS-modal-overlay">
        <div class="ins-TS-modal-dialog" @click.stop>
            <div class="ins-TS-modal-content">
                <div class="ins-TS-modal-header">
                    <h5 class="ins-TS-modal-title">Terry Miller's Answerbook (Task ID: {{ taskId }})</h5>
                </div>
                    <div class="question">
                            <div>
                                <div class="artificial-fieldset">
                                    <label class="artificial-fieldset-legend">Designated Document References</label>
                                    <table class="trainee-info">
                                        <thead>
                                            <th>Doc.</th>
                                            <th>Task</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>FIM</td>
                                                <td>73-01 TASK 807 - Task related to Fault Code 730 107 00</td>
                                            </tr>
                                            <tr>
                                                <td>FIM</td>
                                                <td>71-00 TASK 945 - Task related to CMCS Fault Message 71117</td>
                                            </tr>
                                            <tr>
                                                <td>AMM</td>
                                                <td>73-21-05/401 - EEC Alternator removal/installation</td>
                                            </tr>
                                            <tr>
                                                <td>AMM</td>
                                                <td>78-31-00/201 - T/R Deactivation for Maintenance</td>
                                            </tr>
                                            <tr>
                                                <td>AMM</td>
                                                <td>71-00-00/501 - Power Plant Test Reference Table</td>
                                            </tr>
                                            <tr>
                                                <td>MEL</td>
                                                <td>NIL - No Dispatch</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">Additional Assessment evaluation info</label>
                                <p style="margin-top: 10px;">1. for No 2: replace EEC Alternator </p>
                                                <p>2. for No 3: replace stator only. To replace the alternator you need to open the engine cowlings. <br>
                                                Verbal statement of deactivation/reactivation procedure for the Thrust Reverser shall be given. </p>
                                                <p>3. for No 5: EEC Alternator not listed in MEL </p>
                                                <p>4. Document Info: <br>
                                                US/T-3 Maintenance Training Document Compilation <br>
                                                FIM D633U100-49 - China Airlines - Rev. No. 62 - Mar 15/2006 <br>
                                                AMM D633U101-49 - China Airlines - Rev. No. 62 - Mar 15/2006 <br>
                                                MMEL The Boeing Company - Rev. No. 20 - Jul 13/2006</p>

                        </div>

                            </div>
                            <div class="artificial-fieldset">
                                <label class="artificial-fieldset-legend">Trainee Document References</label>
                                <!-- <h6 class="flight-deck-effect"><b>FLIGHT DECK EFFECT</b></h6> -->
                                <table class="trainee-info-table" :class="{ 'disabled-table': isTableDisabled }">
                                    <thead>
                                        <th style="width: 19%">Doc.</th>
                                        <th style="width: 25%">Rev.</th>
                                        <th style="width: 9%">Eff.</th>
                                        <th>Task</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in documentReferences" :key="index">
                                            <td><input v-model="item.doc" :readonly="isTableDisabled"/></td>
                                            <td><input v-model="item.rev" :readonly="isTableDisabled"/></td>
                                            <td><input v-model="item.eff" :readonly="isTableDisabled"/></td>
                                            <td><input v-model="item.task" :readonly="isTableDisabled"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">Assessment Criteria</label>
                        <table class="trainee-info-table">
                                <tr>
                                    <th>Troubleshooting</th>
                                    <td>a. Valid and correct documents (Rev., Eff., Task, Subject) selected<br>
                                        b. Appropriate troubleshooting procedure stated and noted</td>
                                </tr>
                                <tr>
                                    <th>MEL Procedure</th>
                                    <td>a. Valid and correct MEL task identified and noted, correct dispatch decision made<br>
                                        b. Prescribed procedure demonstrated</td>
                                </tr>
                                <tr>
                                    <th>Location</th>
                                    <td>a. Correct component location determined and stated </td>
                                </tr>
                                <tr>
                                    <th>Removal/Installation</th>
                                    <td>a. Components to be changed identified correctly<br>
                                        b. Appropriate repair/removal/installation procedure stated and noted
                                    </td>
                                </tr>
                                <tr>
                                    <th>Safety Aspects</th>
                                    <td>a. Applicable precautions and safety procedures noted</td>
                                </tr>
                            </table>
                        </div>
                        <div class="ins-answer-section">
                            <div class="artificial-fieldset">
                                <label class="artificial-fieldset-legend">Trainee Answer</label>
                                <div ref="editor" class="quill-editor"></div>
                            </div>
                        </div>
                        <div class="ins-modal-footer">
                            <button @click="close" class="btn btn-secondary" style="margin-top:10px">Close</button>
                            <div class="right">
                                <!--<button @click="saveAnswers" class="btn btn-primary">Save</button>
                                <button @click="submitAnswers" class="btn btn-primary">Submit</button>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
  

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

export default {
    name: 'troubleshootModal',
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        },
        troubleshoot: String,
        taskExplanation: String
    },
    data() {
        return {
            documentReferences: [
                { doc: '', rev: '', eff: '', task: '' },
                { doc: '', rev: '', eff: '', task: '' },
                { doc: '', rev: '', eff: '', task: '' },
                { doc: '', rev: '', eff: '', task: '' }
            ],
            isTableDisabled: true,
            currentQuestionIndex: 0,
            questions: [
                'L/H Wind Anti-Ice Caution on EICAS. System switch to stand by mode, message still present',
                'Gen l off caution message on EICASS. Reset Unsuccessfull',
                'Yaw damper 1 Disengage at time. Reengagement possible',
            ],
            showTroubleshootModal: true,
            editableTroubleshoot: this.troubleshoot,
            currentTab: 'troubleshoot',
            quill: null
        };
    },
    computed: {
        currentQuestion() {
            return this.questions[this.currentQuestionIndex];
        },
        QuestionsCount() {
            return this.questions.length;
        },
    },
    watch: {
        showTroubleshootModal(newValue) {
            if (newValue && this.quill) {
                // Wait for the next tick to ensure the Quill editor is mounted and visible
                this.$nextTick(() => {
                    this.quill.root.innerHTML = this.editableTroubleshoot || '';
                });
            }
        },
        currentTab(newVal, oldVal) {
            if (newVal === 'troubleshoot' && oldVal === 'description') {
                this.initializeQuillEditor();
            }
        },
    },

    mounted() {
        this.initializeQuillEditor();
        console.log(this.taskExplanation);
        this.loadDocumentReferences();
    },
    methods: {
        initializeQuillEditor() {
            if (!this.quill) {
                this.quill = new Quill(this.$refs.editor, {
                    theme: 'snow',
                    modules: {
                        toolbar: false
                    },
                    readOnly:true
                });

                this.quill.root.innerHTML = this.editableTroubleshoot || '';
                this.adjustEditorHeight();
            }
        },
        async loadDocumentReferences() {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            try {
            const response = await axios.get(`/api/logbookentry/${this.logbookId}/${this.taskId}`, { headers });

            const logbookentry_id = response.data.task.id;
            // Check if logbookentry_id is available
            if (!logbookentry_id) {
                console.warn('No logbookentry_id available. Unable to fetch document references.');
                return;
            }

                const responseDoc = await axios.get(`/api/document-references/${logbookentry_id}`, { headers });

                // Update the documentReferences array with the fetched data
                this.documentReferences = responseDoc.data;

                // If the loaded references are less than 4, fill the array to have at least 4 rows
                while (this.documentReferences.length < 4) {
                    this.documentReferences.push({ doc: '', rev: '', eff: '', task: '' });
                }
            } catch (error) {
                console.error('Failed to load document references', error);
                // Handle the error, show a message, etc.
            }
        },
        adjustEditorHeight() {
            const editorElement = this.$refs.editor.querySelector('.ql-editor');
            const toolbar = this.$refs.editor.querySelector('.ql-toolbar');
            const toolbarButtons = this.$refs.editor.querySelectorAll('.ql-toolbar .ql-formats');

            if (editorElement) {
                editorElement.style.height = '360px';
                editorElement.style.fontFamily = 'monospace';
            }
            if (toolbar) {
                toolbar.style.height = '40px';
                toolbar.style.minHeight = '30px';
            }
            if (toolbarButtons) {
                toolbarButtons.forEach(btnGroup => {
                    btnGroup.style.margin = '0 2px';
                });
            }
        },
        close() {
            this.$emit('close');
        },
        prevQuestion() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
                // The computed property 'currentQuestion' will automatically update the question displayed
            }

        },
        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            }
        }
    }
}


</script>

<style scoped>
.question p {
    font-size: 14px;
    font-weight: lighter;
    cursor: not-allowed;
}

.ins-TS-modal-overlay {
    position: fixed;
    padding-top: 1%;
    left: 0;
    background-color: rgba(97, 94, 94, 0.5);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.ins-TS-modal-content {
    background-color: #ffffff;
    max-height: 84vh;
    width: 1000px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    overflow: auto;
}

button {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100px;
    background-color: #616161;
    border: none;
    cursor: pointer;
}

.ins-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ins-modal-footer .right {
    margin-top: 1%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* Adjust the gap between buttons */
}


.artificial-fieldset {
    border: 1px solid #777777;
    padding: 10px;
    margin-top: 20px;
    position: relative;
}

.artificial-fieldset-legend {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    padding: 0 10px;
    font-weight: bold;
}

.question {
    text-align: left;
    font-weight: bold;
}


.disabled-table input {
  cursor: not-allowed;
}
</style>