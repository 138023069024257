<template>
    <div class="signature-modal-overlay" @click="closeSignatureModal">
        <div v-if="showSignatureModal" class="signature-modal" @click.stop>
            <div class="signature-modal-content" style="align-items: center;">
                <h2 style="text-align:center">Upload and Sign PDF</h2>
                <input type="file" @change="onFileChange" class="input">
                <button @click="uploadAndSignPdf" class="uploadButton">Upload and Sign</button>
                <a v-if="downloadUrl" :href="downloadUrl" download="signed_with_annotation.pdf"
                    style="margin-top:3%;">Download Signed PDF</a>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import { PDFDocument, rgb } from 'pdf-lib';

export default {
    data() {
        return {
            showSignatureModal: true,
            selectedFile: null,
            downloadUrl: null,

            verificationResult: null,
        };
    },
    props: {
        show: Boolean
    },
    methods: {
        onFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        async uploadAndSignPdf() {
            if (!this.selectedFile) {
                alert('Please select a file to upload.');
                return;
            }

            const instructorId = localStorage.getItem('user_id');

            try {
                this.instructorName = localStorage.getItem('instructorName');
                let formData = new FormData();
                formData.append('file', this.selectedFile);
                formData.append('instructorId', parseInt(instructorId, 10));
                formData.append('instructorName', this.instructorName);

                const response = await axios.post('/api/sign-pdf', formData, {
                    responseType: 'arraybuffer',
                });

                if (response.data && response.data.pdf && response.data.timestamp) {
                    // Create a new Blob from the received array buffer
                    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                    const timestamp = response.data.timestamp;
                    const pdfBytes = await pdfBlob.arrayBuffer();
                    const pdfDoc = await PDFDocument.load(pdfBytes);
                    const text = `Digitally Signed by ${this.instructorName} on ${timestamp} `;
                    const textSize = 12;
                    const margin = 10; // Margin from the bottom and left
                    // Add "Digitally Signed" text to the PDF
                    const pages = pdfDoc.getPages();
                    pages.forEach(page => {
                        page.drawText(text, {
                            x: margin, // Left margin
                            y: margin, // Bottom margin
                            size: textSize,
                            color: rgb(0, 0, 0),
                        });
                    });

                    // Save the updated PDF
                    const updatedPdfBytes = await pdfDoc.save();
                    const updatedPdfBlob = new Blob([updatedPdfBytes], { type: 'application/pdf' });

                    // Create download link for the updated PDF
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(updatedPdfBlob);
                    downloadLink.download = 'signed_with_annotation.pdf';


                    alert('Please access the Signed PDF using the provied Link');
                    // Set the download URL
                    this.downloadUrl = downloadLink.href;
                } else {
                    console.error('Received empty PDF data from the backend.');
                }
            } catch (error) {
                console.error('Error uploading and signing PDF:', error);
            }
        },
        closeSignatureModal() {
            this.$emit('close');
        },
        async uploadAndVerify(event) {
            const selectedFile = event.target.files[0];
            const formData = new FormData();
            formData.append('file', selectedFile);
            const instructorId = localStorage.getItem('user_id');

            try {
                const response = await axios.post(`/api/verify-signature/${instructorId}`, formData);
                this.verificationResult = response.data.result;
            } catch (error) {
                console.error('Error verifying PDF:', error);
            }
        },
    }
}
</script>
  
<style>
.signature-modal-overlay {
    position: absolute;
    padding-top: 1%;
    left: 0;
    background-color: rgba(97, 94, 94, 0.5);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.signature-modal-content {
    background-color: #ffffff;
    margin-top: 30%;
    height: 30%;
    width: 500px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    overflow: auto;
}

.uploadButton {
    border-radius: 5px;
    width: 200px;
    background-color: #a52c0d;
    color: white;
    margin-right: 20px;
    margin-top: 5%;
}

.input {
    border: 1px solid #ccc;
    /* Grey border with 1px width */
    padding: 5px;
    /* Optional: Add padding to create some space between the border and the input */
    margin-top: 5%;
}
</style>
  