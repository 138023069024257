<template>
  <div>
    <page-header></page-header>
    <div class="content">
      <button @click="multiSignOff" style="font-size: 18px;" class="multiSignOffButton">Multi Sign Off</button>
      <h1>Logbooks:</h1>
      <!-- Display the list of current products -->
      <div v-if="logbooks.length === 0" class="no-logbooks-message">
        <p style="color: rgb(216, 4, 4)">There are no logbooks assigned to you.</p>
      </div>
      <div v-else class="product-container">
        <div v-for="logbook in logbooks" :key="logbook.logbook_id"
          @click="openLogbook(logbook.logbook_id, logbook.product_id, logbook.trainee_id)" class="product-box">
          <div class="product-card">
            <h2 style="font-size: 18px;"><b>ID: {{ logbook.logbook_id }} </b> {{ logbook.name }} </h2>
            <span class="product-name">{{ logbook.productName }}</span>
            <div class="task-summary">
              <p>Total Tasks: {{ logbook.totalTasks }}</p>
              <p>Enabled Tasks: {{ logbook.enabledTasks }}</p>
              <p>Incomplete Tasks: {{ logbook.incompleteTasks }}</p>
            </div>
            <div class="emails">
              <p class="trainee-email">Trainee: {{ logbook.traineeName }}</p>
              <p class="trainee-email">Email: {{ logbook.traineeEmail }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from './InstructorHeader.vue';
import axios from 'axios';

export default {
  name: 'InstructorHome',
  data() {
    return {
      logbooks: [],
      currentTime: '',
      loggedInUser: '',
      showModal: false,
      logbook: {
        totalTasks: 0,
        incompleteTasks: 0,
        enabledTasks: 0,
      },
    };
  },
  components: {
    PageHeader,
  },
  async mounted() {
    try {
      const instructorId = localStorage.getItem('user_id');
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      // Include the trainee_id parameter in the URL
      const result = await axios.get(`/api/instructor/${instructorId}/logbooks`, { headers });
      //console.log(result);
      this.logbooks = result.data.logbooks;
      // Loop through logbooks and fetch tasks for each
      for (const logbook of this.logbooks) {
        const productId = logbook.product_id;
        const productName = await this.fetchproductName(productId, headers);
        logbook.productName = productName;
        localStorage.setItem('productName', logbook.productName);
        const traineeId = logbook.trainee_id;
        localStorage.setItem('trainee_id', traineeId);
        const traineeEmail = await this.fetchTraineeEmail(traineeId, headers);
        logbook.traineeEmail = traineeEmail;
        logbook.traineeName = localStorage.getItem('traineeName');
        const instructorEmail = await this.fetchInstructorEmail(instructorId, headers);
        logbook.instructorEmail = instructorEmail;
        const logbookId = logbook.logbook_id;
        const tasksResult = await axios.get(`/api/${logbookId}/tasks`, { headers });
        logbook.tasks = tasksResult.data.tasks;
        const logbookEntriesResult = await axios.get(`/api/logbookentry/${logbook.logbook_id}`, { headers });
        logbook.totalTasks = tasksResult.data.tasks.length;
        logbook.incompleteTasks = logbookEntriesResult.data.tasks.filter(entry => entry.completed == null).length;
        logbook.enabledTasks = logbookEntriesResult.data.tasks.length;
      }
      let instructor_id = result.data.logbooks[0].instructor_id;
      localStorage.setItem('instructor_id', instructor_id);
      const loggedInUser = localStorage.getItem('username');
      this.loggedInUser = loggedInUser;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized, redirect to login page
        this.$router.push({ name: 'LoginPage' });
      }
    }
  },
  methods: {
    async openLogbook(logbookId, productId, traineeId) {
      localStorage.setItem('logbook_id', logbookId);
      localStorage.setItem('productId', productId);
      let token = localStorage.getItem('token');
      if (!token) {
        this.$router.push({ name: 'examinerLogin' });
      }
      this.$router.push({ name: 'InstructorLogbook', params: { logbookId, productId, traineeId } });
    },
    async fetchproductName(productId, headers) {
      try {
        const response = await axios.get(`/api/products/${productId}`, { headers });
        console.log(response.data.name);
        return response.data.name;
      } catch (error) {
        // Handle error if the product name cannot be fetched
        console.error(`Error fetching product name for product ${productId}: ${error.message}`);
        return ''; // Return an empty string as a fallback
      }
    },
    async fetchTraineeEmail(traineeId, headers) {
      try {
        const response = await axios.get(`/api/users/${traineeId}`, { headers });
        const traineeName = response.data.first_name + ' ' + response.data.last_name;
        localStorage.setItem('traineeName', traineeName);
        return response.data.email;
      } catch (error) {
        // Handle error if the product name cannot be fetched
        console.error(`Error fetching email for trainee ${traineeId}: ${error.message}`);
        return ''; // Return an empty string as a fallback
      }
    },
    async fetchInstructorEmail(instructorId, headers) {
      try {
        const response = await axios.get(`/api/users/${instructorId}`, { headers });
        const instructorName = response.data.first_name + ' ' + response.data.last_name;
        localStorage.setItem('instructorName', instructorName);
        return response.data.email;
      } catch (error) {
        // Handle error if the product name cannot be fetched
        console.error(`Error fetching email for instructor ${instructorId}: ${error.message}`);
        return ''; // Return an empty string as a fallback
      }
    },
    async multiSignOff() {
      this.$router.push({ name: 'MultiSignOff' })
    }
  },
};
</script>
<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  /* Add space from the top */
}

.product-name {
  font-size: 1rem;
  /* Set the font size for the product name */
  color: grey;
  /* Set the text color to grey */
  margin-top: 40px;
  /* Add some space between the logbook name and product name */
  position: absolute;
  margin-left: 1%;
  border: none;
}

.trainee-email,
.instructor-email {
  font-size: 0.9rem;
  /* Set the font size for the emails */
  color: grey;
  /* Set the text color to grey */
  margin: 0;
  margin-top: 8px;
  /* Reset margin to remove any default spacing */
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  width: 90%;
}

h2 {
  font-size: 1.3rem;
  margin-bottom: 25px;
}

h1 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 0px;
  top: 7px;
}

.product-box {
  margin: 10px;
  width: 100%;
  height: 80px;
}

.viewbutton-container {
  display: flex;
  justify-content: flex-start;
  /* Align the button to the left within the container */
  width: 100%;
  /* Make the container take up the full width */
  margin-top: 0;
}

.product-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2),
    /* Right and bottom */
    -3px -3px 3px rgba(0, 0, 0, 0.2),
    /* Left and top */
    0 0 3px rgba(0, 0, 0, 0.2);
  /* Inner shadow */
  transition: transform 0.2s ease-in-out;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 95px;
  margin-bottom: 0;
  /* Center content horizontally within the card */
}

.product-card h2 {
  margin-left: 0;
}

.product-card:hover {
  transform: scale(1.03);
}

.view-button {
  /* Rounded corners for the button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow effect */
  background-color: #0a407a;
  /* Button background color */
  color: #ffffff;
  /* Button text color */
  padding: 10px 15px;
  width: 20%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: auto;
  margin-bottom: 1px;
  /* Add spacing between the text and the button */
}

.task-summary {
  margin-top: 15px;
  text-align: center;
}

.task-summary p {
  margin-bottom: 3px;
  /* Adjust as needed to reduce the gap */
}

.multiSignOffButton {
  position: absolute;
  border-radius: 5px;
  width: 200px;
  background-color: #003f86;
  color: white;
  margin-left: 10px;
  right: 30px;
}

.multiSignOffButton:hover {
  background-color: #012c5e;
}</style>