<template>
    <div class="Evaluation-modal-overlay">
        <div class="evaluation-modal">
            <div class="evaluation-modal-header">
                <h2>Evaluation Form</h2>
            </div>
            <div>
                <h6 class="modal-subheader">CMCS FAULT</h6>
                <p>Maintenance Message 21-22801.</p>
            </div>
            <div class="evaluation-modal-body">
                <form @submit.prevent="submitForm">
                    <div>
                        <h6 class="flight-deck-effect">FLIGHT DECK EFFECT</h6>
                        <table class="flight-deck-table">
                            <tr>
                                <td style="width:500px; text-align: left;">Logbook entry</td>
                                <td><input type="checkbox" v-model="form.logbookEntry" /></td>
                            </tr>
                            <tr>
                                <td style="width:500px; text-align: left;">Flight deck effect</td>
                                <td><input type="checkbox" v-model="form.flightDeckEffect" /></td>
                            </tr>
                            <tr>
                                <td style="width:500px; text-align: left;">Crew/Maintenance observation</td>
                                <td><input type="checkbox" v-model="form.observation" /></td>
                            </tr>
                            <tr>
                                <td style="width:500px; text-align: left; border:none">ECAS status: PACK L</td>
                            </tr>
                        </table>
                    </div>

                    <div class="questions-section">
                        <h6 class="questions">QUESTIONS</h6>
                        <ol class="questions-list">
                            <li>
                                <label>Indicate the airplane status : NO GO, GO or GO IF</label>
                            </li>
                            <li>
                                <label>If airplane is GO IF:</label>
                                <div class="indent">
                                    <p>Indicate the related MEL procedure item </p>
                                    <p>Indicate the related repair interval" </p>
                                    <p>Indicate the related maintenance procedure (if applicable)"</p>
                                </div>
                            </li>
                            <li>
                                <p>Indicate the related trouble shooting procedure</p>
                                
                            </li>
                        </ol>
                    </div>
                    <div class="answer-section">
                        <h6 class="answers">ANSWERS</h6>
                        <textarea v-model="form.troubleShootingProcedure" class="ts-textarea"></textarea>
                    </div>
                    <div class="form-section">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'EvaluationFormModal2',
    props: {

    },
    data() {
        return {
            form: {
                showEvaluationFormModal: true,
                logbookEntry: false,
                flightDeckEffect: false,
                observation: false,
                ecasStatus: '',
                airplaneStatus: '',
                melProcedure: '',
                repairInterval: '',
                maintenanceProcedure: '',
                troubleShootingProcedure: '',
                traineeSignature: '',
                assessorValidation: '',
                date: ''
            }
        };
    },
    methods: {
        close() {
            this.$emit('update:show', false);
        },
        submitForm() {
            // Handle the form submission logic here
            console.log(this.form);
            this.close();
        }
    }
};
</script>
  
<style scoped>
.Evaluation-modal-overlay {
    position: fixed;
    padding-top: 1%;
    left: 0;
    background-color: rgba(97, 94, 94, 0.5);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: auto;
}

.evaluation-modal {
    background-color: #ffffff;
    overflow-y: auto;
    /* Enables vertical scrolling if needed */
    max-height: 85vh;
    min-width: 60vw;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    padding: 20px;
}

.evaluation-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.evaluation-modal-body {
    margin-top: 20px;
}

.form-section {
    margin-bottom: 20px;
}

.question {
    margin-top: 10px;
}

button {
    cursor: pointer;
}

.modal-subheader,
.flight-deck-effect,
.questions,
.answers {
    background: #F3F3F3;
    padding: 10px;
    text-align: center;
    border-top: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3;
}

.flight-deck-table {
    border-collapse: collapse;
    margin: 0 auto;
}

.flight-deck-table tr:nth-child(odd) {
    background-color: rgba(219, 219, 219, 0.904);
}

.flight-deck-effect{
    align-items: center;
}

.ts-textarea{
    align-items: CENTER;
    margin-left:60px;
    width: 800px;
    height: 300px;
}
</style>
  