<template>
  <head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  </head>
  <div>
    <Header />
    <div v-if="isOffline" class="offline-box">
      <p>Offline Mode</p>
    </div>
    <div v-if="isSyncing" class="syncing-box">
      <p>You're online! Syncing data...</p>
    </div>
    <div class="table-section-instructor">
      <div class="logbook-name">
        <table class="logbook-name-table">
          <tr>
            <td class="InslogbookName">{{ logbookName }}</td>
            <td style="border:none"> <button @click="createPDFReport" style="font-size: 18px;"
                class="InspdfButton">Download PDF</button></td>
          </tr>
        </table>
      </div>
      <div class="ins-pagination-button-container">
        <div class="ins-pagination-container">
          <Pagination :total-pages="totalPages" :per-page="perPage" :current-page="currentPage"
            @page-change="onPageChange" />
        </div>
        <div class="ins-pagination-info">
          Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredTasks.length }} Tasks
        </div>
      </div>
      <div class="signoff-buttons">
        <button @click="toggleMultiSignOffMode" class="multi-sign-off-button">
          {{ multiSignOffMode ? 'De-select All' : 'Select All' }}
        </button>
        <button @click="signOffAll" class="multi-sign-off-button">Sign Off All</button>
      </div>
      <table class="data-table-instructor-logbook">
        <thead>
          <tr>
            <th class="task-no">Task No</th>
            <!--<th class="reference">Reference</th>-->
            <th class="module">Module
              <select v-model="selectedmodule">
                <option value="All">All</option>
                <option v-for="module in uniquemodules" :key="module" :value="module">{{ module }}</option>
              </select>
            </th>
            <th class="chapt-id">Chapt No
              <select v-model="selectedChapter">
                <option value="All">All</option>
                <option v-for="chapter in uniqueChapters" :key="chapter" :value="chapter">{{ chapter }}</option>
              </select>
            </th>
            <th class="area">Area <br>
              <select v-model="selectedLocation">
                <option value="All">All</option>
                <option v-for="area in uniqueAreas" :key="area" :value="area">{{ area }}</option>
              </select>
            </th>
            <th class="AMTOSS">AMTOSS<br>
              <select v-model="selectedAMTOSS">
                <option value="All">All</option>
                <option v-for="AMTOSS in unqiueAMTOSS" :key="AMTOSS" :value="AMTOSS">{{ AMTOSS }}</option>
              </select>
            </th>
            <th v-if="isAssessorSelected" class="is_exam">Being Assessed</th>
            <th v-if="!isAssessorSelected" class="enable">Enable Tasks</th>
            <th v-if="isAssessorSelected" class="enable">Assessment Complete</th>
            <th class="activity">Activity</th>
            <th class="completed">
              <div v-if="!isAssessorSelected">
                Completed
                <select v-model="selectedCompleted" style="width:90%">
                  <option value="All tasks">All tasks</option>
                  <option value="completed tasks">Completed tasks</option>
                  <option value="not completed tasks">Incomplete tasks</option>
                </select>
              </div>
              <div v-else>
                <!-- Display a static text or nothing if only completed tasks are to be shown -->
                Completed tasks
              </div>
            </th>
            <th class="inssign">Inst. Sign Off
              <select v-model="selectedSignOff" style="width:110%">
                <option value="All tasks">All tasks</option>
                <option value="signed off tasks">Signed Off</option>
                <option value="not signed off tasks">Not Signed Off</option>
              </select>
            </th>
            <th v-if="!isAssessorSelected" class="publicnotes">Public Notes</th>
            <th v-if="!isAssessorSelected" class="privatenotes">Private Notes</th>
            <th class="assess">Assess</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(task, index) in paginatedTasks" :key="index">
            <td>{{ task.task_no }}</td>
            <!--<td>{{ task.reference }}</td>-->
            <td>{{ task.module }}</td>
            <td>{{ task.chapt_no }}</td>
            <td>{{ task.area }}</td>
            <td>{{ task.AMTOSS }}</td>
            <td v-if="isAssessorSelected">
              <input type="checkbox" v-model="task.being_assessed" @change="toggleBeingAssessed(task)"
                :disabled="task.is_assessed">
            </td>
            <!-- <td v-else>
              {{ task.is_exam ? 'Yes' : 'No' }}
            </td> -->
            <!-- <td>
              <input type="checkbox" v-model="task.is_exam" @change="toggleIsExam(task)" class="custom-checkbox"
                id="center-icons">
            </td> -->
            <td v-if="!isAssessorSelected">
              <input type="checkbox" v-model="task.enabled" @change="toggleEnabled(task)" :disabled="task.completed">
            </td>
            <td v-else>
              <input type="checkbox" v-model="task.is_assessed" @change="toggleIsAssessed(task)"
                :disabled="!task.being_assessed">
            </td>
            <!-- <td @click="openTaskDescriptionModal(task)"
              :class="{ 'Description-data': task.is_exam, 'Other-description': !(task.is_exam) }">
              {{ task.activity }}
            </td> -->
            <td>
              <div :style="{ color: task.is_assessed ? 'green' : 'blue' }">
                {{ task.activity }}
              </div>
              <div v-if="task.is_exam" :style="{ color: task.is_assessed ? 'green' : 'red' }">
                <b>{{ task.is_assessed ? '*Assessed' : (task.being_assessed ? '*Selected for Assessment' :
                  '*Evaluable') }}</b>
              </div>
            </td>
            <td>
              <div>{{ task.completed }}</div>
              <p v-if="task.se_so" class="se_so" title="by Joe Smith at 13:54:21">
      <i v-if="task.se_so" class="fas fa-check"></i> SE Signed Off 
    </p>
            </td>
            <td v-if="multiSignOffMode && !task.ins_so">
              <button class="instructor_signoff" :class="{ 'disabled-signoff': !task.completed }"
                :disabled=!task.completed @click.prevent="confirmSignOff(task)">SIGN OFF</button>
              <input type="checkbox" v-if="task.completed && !task.ins_so" v-model="task.selectedForSignOff"
                @change="toggleSignOff(task)" />
            </td>
            <td v-else>
              <div v-if="task.ins_so">
                {{ task.ins_so }}
              </div>
              <div v-else>
                <button v-if="!isAssessorSelected" class="instructor_signoff"
                  :class="{ 'disabled-signoff': !task.se_so }" :disabled=!task.se_so
                  @click.prevent="confirmSignOff(task)">SIGN OFF</button>
              </div>
            </td>
            <td v-if="!isAssessorSelected">
              <button class="notesButton" @click="openEditor(task.task_id, true)">
                <i :class="{
                  'fas fa-sticky-note': true,
                  'icon-green': publicNotes[task.task_id] && publicNotes[task.task_id].trim() !== '',
                  'icon-grey': !publicNotes[task.task_id] || publicNotes[task.task_id].trim() === ''
                }"></i>
              </button>
            </td>
            <td v-if="!isAssessorSelected">
              <button class="notesButton" @click="openEditor(task.task_id)">
                <i :class="{
                  'fas fa-sticky-note': true,
                  'icon-green': privateNotes[task.task_id] && privateNotes[task.task_id].trim() !== '',
                  'icon-grey': !privateNotes[task.task_id] || privateNotes[task.task_id].trim() === ''
                }"></i>
              </button>
              </td>
            <td>
  <div v-if="task.is_assessed">
    <a style="text-decoration: underline; color: blue; cursor: pointer;">View Results</a>
  </div>
  <div v-if="task.being_assessed && !task.is_assessed && isAssessorSelected">
    <a style="text-decoration: underline; color: blue; cursor: pointer;">Assess Trainee</a>
  </div>
</td> 
          </tr>
        </tbody>
      </table>
      <div class="evaluationSection">
        <!-- <button class="evaluationButton" @click="openDocModal()">Document Reference</button> -->
        <button class="evaluationButton" @click="toggleSignatureModal">Add Digital Signature</button>

        <button class="evaluationButton" @click="openEvaluationModal()">Evaluation Sheet</button>
      </div>
      <QuillEditor v-if="showQuillEditor" :task-id="currentTaskId" :initialContent="getCurrentTaskNotes"
        :displayTaskId="currentTaskId" @saveNotes="closeQuillEditor">
      </QuillEditor>
      <instroubleshootModal v-if="showTroubleshootModal" :task-id="currentTaskId" :logbook-id="logbookId"
        :troubleshoot="currentTroubleshoot" :task-explanation="currentTaskExplanation" @close="closeTroubleshootModal">
      </instroubleshootModal>
      <TaskDescriptionModal v-if="showTaskDescriptionModal" :task-id="currentTaskId" :logbook-id="logbookId"
        :task-explanation="currentTaskExplanation" @close="closeTaskDescriptionModal"></TaskDescriptionModal>
      <evaluation-form-modal v-if="showEvaluationFormModal" :tasks-count="tasksCount" :completed-tasks="tasksCompleted"
        :evaluation-id="evaluationId" :logbook-id="logbookId" :fetched-data="fetchedEvaluationData"
        @close="closeEvaluationModal"></evaluation-form-modal>
        <signature-modal v-if="showSignatureModal" @close="closeSignatureModal"></signature-modal>

        <document-reference-modal v-if="showDocModal" @update:showModal="showDocModal = $event" @close="closeDocModal" />

    </div>
  </div>
</template>

<script>
import Header from './InstructorHeader.vue';
import axios from 'axios';
import './InstructorLogbook.css';
import Pagination from '@/components/Examiner/TablePagination.vue';
import QuillEditor from '@/components/notes/quill.vue';
import LogbookReport from './InstructorLogbookReport.vue';
import instroubleshootModal from '@/components/Instructor/InsTroubleshootModal.vue';
import TaskDescriptionModal from '@/components/Trainee/TaskDescriptionModal.vue';
import EvaluationFormModal from '@/components/Instructor/EvaluationFormModal2.vue';
import SignatureModal from './DigitalSignatureModal.vue';
import DocumentReferenceModal from './InsDocumentReferenceModal.vue';


import Localbase from 'localbase';
let db = new Localbase('myDatabase');

export default {
  name: 'InstructorLogbook',
  components: {
    Pagination,
    Header,
    QuillEditor,
    instroubleshootModal,
    TaskDescriptionModal,
    EvaluationFormModal,
    SignatureModal,
    DocumentReferenceModal
  },
  data() {
    return {
      logbooks: [],
      tasks: [],
      logbookName: '',
      productId: localStorage.getItem('productId') || null,
      logbookId: localStorage.getItem('logbook_id'),
      mergedTasks: [],
      editorContent: '',
      showQuillEditor: false,
      showTroubleshootModal: false,
      showTaskDescriptionModal: false,
      showEvaluationFormModal: false,
      showDocModal: false,
      fetchedEvaluationData: null,
      showSignatureModal: false,
      tasksCount: 0,
      privateNotes: {},
      publicNotes: {},
      notesContent: '',
      showDropdown: false,
      selectedAMTOSS: 'All',
      selectedCompleted: 'All tasks',
      selectedLevel: 'All',
      selectedmodule: 'All',
      selectedCategory: 'All',
      selectedChapter: 'All',
      selectedisexam: 'All',
      selectedSignOff: 'All tasks',
      selectedLocation: 'All',
      selectedArea: 'All',
      isSyncing: false,
      isOffline: !navigator.onLine,
      selectedTasks: [],
      multiSignOffMode: false,
      filteredTasksLength: 0,
      currentPage: 1,
      perPage: 10,
    };
  },
  computed: {
    getCurrentTaskNotes() {
      const noteSource = this.isNotePublic ? this.publicNotes : this.privateNotes;
      return noteSource[this.currentTaskId] || '';
    },
    filteredTasks() {
      return this.mergedTasks.filter(task => {
        const amtossMatches = this.selectedAMTOSS === 'All' || task.AMTOSS === this.selectedAMTOSS;
        const completedMatches =
  this.selectedCompleted === 'All tasks' ||
  (this.selectedCompleted === 'completed tasks' && task.completed !== null) ||
  (this.selectedCompleted === 'not completed tasks' && task.completed === null);

const signedOffMatches =
  this.selectedSignOff === 'All tasks' ||
  (this.selectedSignOff === 'signed off tasks' && task.ins_so !== null) ||
  (this.selectedSignOff === 'not signed off tasks' && task.ins_so === null);
        const levelMatches = this.selectedLevel === 'All' || task.level === parseInt(this.selectedLevel);
        const moduleMatches = this.selectedmodule === 'All' || task.module === this.selectedmodule;
        const chapterMatches = this.selectedChapter === 'All' || task.chapt_no === this.selectedChapter;
        const categoryMatches = this.selectedCategory === 'All' || task.category === this.selectedCategory;
        const isexamMatches = this.selectedisexam === 'All' || task.is_exam === this.selectedisexam;
        const areaMatches = this.selectedArea === 'All' || task.area === this.selectedArea;

        return amtossMatches && completedMatches && signedOffMatches && levelMatches && moduleMatches && isexamMatches && chapterMatches && categoryMatches && areaMatches;
      });
    },
    unqiueAMTOSS() {
      const amtoss = this.tasks.map(task => task.AMTOSS);
      return [...new Set(amtoss)];
    },
    uniqueisexam() {
      const isexams = this.tasks.map(task => task.is_exam);
      return [...new Set(isexams)];
    },
    uniqueChapters() {
      const chapters = this.tasks.map(task => task.chapt_no);
      return [...new Set(chapters)]; // This will remove any duplicates and return unique values.
    },
    uniqueLocations() {
      const locations = this.tasks.map(task => task.location);
      return [...new Set(locations)];
    },
    uniqueLevels() {
      const levels = this.tasks.map(task => task.level);
      return [...new Set(levels)];
    },
    uniqueAreas() {
      const areas = this.tasks.map(task => task.area);
      return [...new Set(areas)];
    },
    uniquemodules() {
      const modules = this.tasks.map(task => task.module);
      return [...new Set(modules)];
    },
    uniquecategories() {
      const categories = this.tasks.map(task => task.category);
      return [...new Set(categories)];
    },
    totalPages() {
      return Math.ceil(this.filteredTasks.length / this.perPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredTasks.length ? end : this.filteredTasks.length;
    },
    paginatedTasks() {
      const start = this.startIndex;
      const end = this.endIndex;
      return this.filteredTasks.slice(start, end);
    },
    localIsAssessorSelected: {
      get() {
        return this.isAssessorSelected;
      },
      set(newValue) {
        this.$emit('update:isAssessorSelected', newValue);
      }
    }
  },

  methods: {
    hasNotes(taskId) {
      return this.publicNotes[taskId] || this.privateNotes[taskId];
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    toggleSignatureModal(){
      this.showSignatureModal = true;
    },
    closeSignatureModal() {
      this.showSignatureModal = false;
    },
    openDocModal(){
      this.showDocModal = true;
    },
    closeDocModal() {
      this.showDocModal = false;
    },
    async handleDigitalSignature() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const instructorId = localStorage.getItem('user_id');

        // Check if a digital signature already exists
        const checkResponse = await axios.get(`/api/check-signature?instructorId=${instructorId}`, { headers });
        let proceedWithSigning = false;

        if (checkResponse.data.isSigned) {
          // Confirm overwrite if a signature already exists
          proceedWithSigning = window.confirm("A digital signature already exists. Do you want to overwrite it?");
        } else {
          // Confirm creating a new signature if none exists
          proceedWithSigning = window.confirm("Do you want to create a new signature? This action will record the current timestamp.");
        }

        if (!proceedWithSigning) {
          return; // Do nothing if the user cancels
        }

        // Post request to create or update the digital signature
        const endpoint = checkResponse.data.isSigned ? '/api/update-signature' : '/api/sign-pdf';
        const response = await axios.post(endpoint, { instructorId }, { headers });

        console.log('Digital signature response:', response.data);
        alert('New digital signature recorded');

      } catch (error) {
        console.error('Error in digital signature process:', error);
        // Handle the error (e.g., show an error message)
      }
    },
    async toggleEnabled(task) {
      if (!task.enabled) {
        const confirmation = window.confirm('Are you sure you want to disable this task?');
        if (!confirmation) {
          task.enabled = true; // Keep checkbox checked if the user cancels
          return;
        }
      }
      const logbookId = localStorage.getItem('logbook_id');
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      if (navigator.onLine) {
        try {
          await axios.post(`/api/logbookentry/`, {
            logbook_id: logbookId,
            task_id: task.task_id,
            enabled: task.enabled,
            is_submitted: false
          }, { headers });
        } catch (error) {
          console.error("Error updating 'enabled' property:", error);
        }
      } else {
        // Offline mode: Store the change in Localbase
        db.collection('TaskEnabledStatus').doc(String(task.task_id)).set({
          taskId: String(task.task_id),
          logbookId: logbookId,
          enabled: task.enabled
        }).then(() => {
          console.log('Enabled status saved offline');
        });
      }

      // Update the local 'enabled' property
      const matchingTask = this.mergedTasks.find(t => t.task_id === task.task_id);
      if (matchingTask) {
        matchingTask.enabled = task.enabled;
      }
    },
    async toggleIsExam(task) {
      if (!task.is_exam) {
        const confirmation = window.confirm('Are you sure you want to deselect the option?');
        if (!confirmation) {
          task.is_exam = true; // If the user cancels the confirmation, keep the checkbox checked
          return;
        }
      }
      // Toggle the is_exam value
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        console.log("task_id", task.task_id);
        console.log("is_exam", task.is_exam)
        await axios.put(`/api/tasks/${task.task_id}`, {
          is_exam: task.is_exam // Send the value to the backend
        }, { headers });
      } catch (error) {
        console.error("Error updating 'is_exam' property:", error);
        // If the update fails, revert the checkbox state
        task.is_exam = !task.is_exam;
      }
    },
    async toggleBeingAssessed(task) {
      if (!task.being_assessed) {
        const confirmation = window.confirm('Are you sure you want to deselect the option?');
        if (!confirmation) {
          task.being_assessed = true; // If the user cancels the confirmation, keep the checkbox checked
          return;
        }
      }
      // Toggle the is_exam value
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        console.log("task_id", task.task_id);
        console.log("being_assessed", task.being_assessed)
        await axios.put(`/api/tasks/${task.task_id}`, {
          being_assessed: task.being_assessed // Send the value to the backend
        }, { headers });
      } catch (error) {
        console.error("Error updating 'being_assessed' property:", error);
        // If the update fails, revert the checkbox state
        task.being_assessed = !task.being_assessed;
      }
    },
    async toggleIsAssessed(task) {
      if (!task.is_assessed) {
        const confirmation = window.confirm('Are you sure you want to deselect the option?');
        if (!confirmation) {
          task.is_assessed = true; // If the user cancels the confirmation, keep the checkbox checked
          return;
        }
      }
      // Toggle the is_assessed value
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        console.log("task_id", task.task_id);
        console.log("is_assessed", task.is_assessed)
        await axios.put(`/api/tasks/${task.task_id}`, {
          is_assessed: task.is_assessed // Send the value to the backend
        }, { headers });
      } catch (error) {
        console.error("Error updating 'is_assessed' property:", error);
        // If the update fails, revert the checkbox state
        task.is_assessed = !task.is_assessed;
      }
    },
    async setTimestamp(task) {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      task.ins_so = formattedDate;
      task.isReadOnly = true;

      const logbookId = localStorage.getItem('logbook_id');

      if (!navigator.onLine) {
        // Save to Localbase when offline
        db.collection('InsCompletedTasks').doc(String(task.task_id)).set({
          taskId: String(task.task_id),  // Convert task_id to string
          ins_so: formattedDate,
          logbookId: logbookId,
        }).then(() => {
          console.log('Task saved offline');
        });
      } else {
        try {
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          const response = await axios.put(`/api/logbookentry/${this.logbookId}/${task.task_id}`, {
            ins_so: formattedDate
          }, { headers });
          console.log("success in updating logbook entry:", response);
          // Handle the response if needed (e.g. check if the update was successful)
        } catch (error) {
          console.error("Error updating logbook entry:", error);
        }
      }
    },
    async syncData() {
      // Check if there's an internet connection
      if (navigator.onLine) {
        this.isSyncing = true;

        // Synchronize completed tasks
        const completedTasksToSync = await db.collection('InsCompletedTasks').get();
        await this.syncCompletedTasks(completedTasksToSync);

        // Synchronize enabled status changes
        const enabledStatusToSync = await db.collection('TaskEnabledStatus').get();
        await this.syncEnabledStatusChanges(enabledStatusToSync);

        this.isSyncing = false;

        // Reload the page to reflect the synced data
        location.reload();
      } else {
        console.log("No internet connection. Data sync postponed.");
      }
    },

    async syncCompletedTasks(tasksToSync) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      for (const task of tasksToSync) {
        try {
          await axios.put(`/api/logbookentry/${task.logbookId}/${task.taskId}`, {
            ins_so: task.ins_so
          }, { headers });
          await db.collection('InsCompletedTasks').doc(task.taskId).delete();
        } catch (error) {
          console.error('Error syncing completed task:', error);
        }
      }
    },

    async syncEnabledStatusChanges(tasksToSync) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      for (const task of tasksToSync) {
        try {
          await axios.post(`/api/logbookentry/`, {
            logbook_id: task.logbookId,
            task_id: task.taskId,
            enabled: task.enabled,
            is_submitted: false,
          }, { headers });
          await db.collection('TaskEnabledStatus').doc(task.taskId).delete();
        } catch (error) {
          console.error('Error syncing enabled status:', error);
        }
      }
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const logbookId = localStorage.getItem('logbook_id');

        const [logbookResponse, inslogbookEntryResponse, tasksResponse] = await Promise.all([
          axios.get(`/api/logbooks/${logbookId}`, { headers }),
          axios.get(`/api/logbookentry/${logbookId}`, { headers }),
          axios.get(`/api/${logbookId}/tasks`, { headers })
        ]);
        this.logbookName = logbookResponse.data.name;
        this.tasks = tasksResponse.data.tasks;
        this.logbookEntries = inslogbookEntryResponse.data.tasks;

        this.mergeData();
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },

    mergeData() {
      this.mergedTasks = this.tasks.map(task => {
        const matchingEntry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
        return {
          ...task,
          completed: matchingEntry ? matchingEntry.completed : null,
          ins_so: matchingEntry ? matchingEntry.ins_so : null,
          enabled: matchingEntry ? matchingEntry.enabled === 1 : 0,
          is_exam: !!task.is_exam,
        };
      });
    },

    shouldShowSignOffButton(task) {
      return (
        !task.complete && // Check if complete is empty (not signed off)
        task.work_order_no !== null &&
        task.work_order_no.toString().trim() !== '' &&
        task.task_detail !== null &&
        task.task_detail.toString().trim() !== '' &&
        task.category !== null &&
        task.category.toString().trim() !== '' &&
        task.ATA !== null &&
        task.ATA.toString().trim() !== ''
      );
    },
    async fetchPublicNotesForTask(taskId) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = `/api/instructorPublicNotes/${taskId}`;

      axios.get(endpoint, { headers })
        .then(response => {
          if (response.data && response.data.content) {
            this.publicNotes[taskId] = response.data.content;
          } else {
            this.publicNotes[taskId] = '';
          }
        })
        .catch(error => {
          console.error(`Error fetching public notes for task ${taskId}:`, error);
        });
    },

    async fetchPrivateNotesForTask(taskId) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = `/api/instructorPrivateNotes/${taskId}`;

      axios.get(endpoint, { headers })
        .then(response => {
          if (response.data && response.data.content) {
            this.privateNotes[taskId] = response.data.content;
          } else {
            this.privateNotes[taskId] = '';
          }
        })
        .catch(error => {
          console.error(`Error fetching private notes for task ${taskId}:`, error);
        });
    },

    async savePublicNotes(taskId, content) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      const postEndpoint = '/api/instructor-public-notes';
      const putEndpoint = `/api/instructorPublicNotes/${taskId}`;

      if (this.publicNotes[taskId]) {
        axios.put(putEndpoint, { content }, { headers })
          .then(response => {
            this.publicNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error updating public note:', error);
          });
      } else {
        const userId = localStorage.getItem('instructor_id');
        axios.post(postEndpoint, { task_id: taskId, content, user_id: userId }, { headers })
          .then(response => {
            this.publicNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error creating public note:', error);
          });
      }
    },

    async savePrivateNotes(taskId, content) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      const postEndpoint = '/api/instructor-private-notes';
      const putEndpoint = `/api/instructorPrivateNotes/${taskId}`;

      if (this.privateNotes[taskId]) {
        axios.put(putEndpoint, { content }, { headers })
          .then(response => {
            this.privateNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error updating private note:', error);
          });
      } else {
        const userId = localStorage.getItem('instructor_id');
        axios.post(postEndpoint, { task_id: taskId, content, user_id: userId }, { headers })
          .then(response => {
            this.privateNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error creating private note:', error);
          });
      }
    },

    async openEditor(taskId, isPublic = false) {
      // Clear the note for the current task ID first
      if (isPublic) {
        if (!this.publicNotes) this.publicNotes = {};
        this.publicNotes[this.currentTaskId] = '';  // Clear the previous public note
      } else {
        if (!this.privateNotes) this.privateNotes = {};
        this.privateNotes[this.currentTaskId] = '';  // Clear the previous private note
      }

      // Update the current task ID
      this.currentTaskId = taskId;
      this.isNotePublic = isPublic;  // Set the note type
      // Fetch the appropriate note for the new task ID
      if (isPublic) {
        await this.fetchPublicNotesForTask(taskId);
      } else {
        await this.fetchPrivateNotesForTask(taskId);
      }

      this.showQuillEditor = true;
    },

    closeQuillEditor(payload) {
      if (this.isNotePublic) {
        this.savePublicNotes(payload.taskId, payload.content);
        this.fetchPublicNotesForTask(payload.taskId);
        this.publicNotes[this.currentTaskId] = '';  // Clear the public note
      } else {
        this.savePrivateNotes(payload.taskId, payload.content);
        this.fetchPrivateNotesForTask(payload.taskId);
        this.privateNotes[this.currentTaskId] = '';  // Clear the private note
      }
      this.showQuillEditor = false;
    },
    isTaskVisible(task) {
      if (this.taskFilter === 'all') return true;
      if (this.taskFilter === 'completed' && task.completed) return true;
      if (this.taskFilter === 'uncompleted' && !task.completed) return true;
      return false;
    },
    goHome() {
      this.$router.push({ name: 'InstructorHome' }) // Go back to the home page
    },
    confirmSignOff(task) {
      if (confirm('Are you sure you want to sign off?')) {
        this.setTimestamp(task);
      }
    },
    signOffAll() {
      if (this.selectedTasks.length === 0) {
        alert('No tasks selected for sign-off.');
        return;
      }
      if (confirm('Are you sure you want to sign off all selected tasks?')) {
        this.selectedTasks.forEach(task => {
          this.setTimestamp(task);
        });

      }
      this.selectedTasks = [];
    },
    toggleMultiSignOffMode() {
      if (this.multiSignOffMode) {
        // If currently in Multi-Sign Off mode, exit it
        this.multiSignOffMode = false;
        this.selectedTasks = [];
      } else {
        // If not in Multi-Sign Off mode, switch to Select All mode
        this.multiSignOffMode = true;
        // Perform the "Select All" action here
        this.toggleAllTasksSelection();
      }
    },
    toggleSignOff(task) {
      if (task.selectedForSignOff) {
        this.selectedTasks.push(task);
      } else {
        const index = this.selectedTasks.findIndex((selectedTask) => selectedTask.task_id === task.task_id);
        if (index !== -1) {
          this.selectedTasks.splice(index, 1);
        }
      }
    },
    toggleAllTasksSelection() {
      this.selectedTasks = [];

      this.filteredTasks.forEach(task => {
        if (task.completed && !task.ins_so) {
          task.selectedForSignOff = true;
          this.selectedTasks.push(task);
        }
      });

      if (this.selectedTasks.length === 0) {
        alert("All tasks have been already signed off.");
        this.multiSignOffMode = false;
      }
    },
    async createPDFReport() {
      const logbookName = this.logbookName;
      LogbookReport.methods.generatePDF(this.filteredTasks, logbookName);
    },
    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
    },
    openTroubleshootModal(task) {
      this.showTroubleshootModal = true;
      const entry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
      this.currentTaskExplanation = task.task_explanation;
      if (entry) {
        this.currentTaskId = task.task_id;  // Make sure 'task_id' is the correct property name
        this.currentTroubleshoot = entry.troubleshoot ? entry.troubleshoot.replace(/<br\s*\/?>/gi, '\n') : '';
      } else {
        console.error('No logbook entry found for task id:', task.task_id);
        this.currentTroubleshoot = '';  // Set to empty string if not found
      }
    },
    closeTroubleshootModal() {
      this.showTroubleshootModal = false;
    },
    // openTaskDescriptionModal(task) {
    //   if (task.is_exam) {
    //     this.openTroubleshootModal(task);
    //   } else {
    //     this.currentTaskId = task.task_id;
    //     this.currentTaskExplanation = task.task_explanation;
    //     this.showTaskDescriptionModal = true;
    //   }
    // },
    closeTaskDescriptionModal() {
      this.showTaskDescriptionModal = false;
    },
    fetchEvaluationData() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const logbookId = this.logbookId;

      axios.get(`/api/evaluations?logbook_id=${logbookId}`, { headers })
        .then(response => {
          const evaluation = response.data[0];
          if (evaluation) {
            this.evaluationId = evaluation.id; // Store the fetched evaluation ID
            this.fetchedEvaluationData = evaluation; // Pass the evaluation data directly
            console.log(this.fetchedEvaluationData)
          }
        })
        .catch(error => {
          console.error('There was an error fetching the evaluation:', error);
        });
    },

    openEvaluationModal() {
      this.showEvaluationFormModal = true;
      this.tasksCount = this.tasks.length;
      this.tasksCompleted = this.logbookEntries?.completed?.length || 0;
      this.fetchEvaluationData();
    },
    closeEvaluationModal() {
      this.showEvaluationFormModal = false;
    },
    updateIsAssessorSelected(newValue) {
      this.$emit('update:isAssessorSelected', newValue);
    }
  },
  async mounted() {
    const isAssessorSelected = false; // Convert the string to a boolean
this.isAssessorSelected = isAssessorSelected;
console.log(isAssessorSelected);
    this.$route.params.logbookId;
    this.$route.params.productId;
    this.$route.params.traineeId;
    window.addEventListener('online', this.syncData);
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.fetchData();
    if (isAssessorSelected) {
      this.selectedCompleted = 'completed tasks';
      this.selectedisexam = true;
    }
  },
  beforeMount() {
    window.removeEventListener('online', this.syncData);
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
}
</script>

<style scoped>
p.se_so {
  margin-top: 0;
  margin-bottom: 0;
  color: rgb(0, 162, 255);
  font-size: 13px;
}
</style>