<template>
    <div v-if="showDocModal" class="doc-modal-overlay">
        <div class="doc-modal-content">
            <h3>Participant Document References</h3>
            <h6 style="text-align: center; font-weight: bold;">EXAMPLES</h6>
            <table style="border: 1px solid black">
                <thead style="text-align: left;">
                    <tr style="background-color:rgba(60, 132, 241, 0.342);  border: 1px solid black">
                        <th class="doc">Doc.</th>
                        <th class="rev">Rev.</th>
                        <th class="eff">Eff.</th>
                        <th class="taskno">Task Number</th>
                    </tr>
                </thead>
                <tbody style="text-align: left;">
                    <!-- Example entries from the image -->
                    <tr style="text-align: left;">
                        <td>TSM</td>
                        <td>OCT13 / 079</td>
                        <td>123</td>
                        <td>System Operational Test</td>
                    </tr>
                    <tr>
                        <td>MEL</td>
                        <td>JAN14 / 065</td>
                        <td>123</td>
                        <td>Deactivation Procedure</td>
                    </tr>
                    <tr>
                        <td>AMM</td>
                        <td>JAN14 / 065</td>
                        <td>123</td>
                        <td>Removal/Installation</td>
                    </tr>
                    <tr>
                        <td>Job Card</td>
                        <td>OCT13 / 079</td>
                        <td>123</td>
                        <td>Tire Pressure Check</td>
                    </tr>
                </tbody>
            </table>
            <table style="margin-top:50px;">
                <thead style="text-align: left;">
                    <tr style="background-color:rgba(60, 132, 241, 0.342);  ">
                        <th class="doc">Doc.</th>
                        <th class="rev">Rev.</th>
                        <th class="eff">Eff.</th>
                        <th class="taskno">Task Number</th>
                    </tr>
                </thead>
                <tbody >
                    <!-- Example entries from the image -->
                    <tr>
                        <td><input type="text" name="doc" value="MEL" /></td>
                        <td><input type="text" name="rev" value="AUG15 / 081" /></td>
                        <td><input type="text" name="eff" value="223" /></td>
                        <td><input type="text" name="taskNumber" value="Fuel Gauge Check" /></td>
                    </tr>
                    <!-- Empty rows with input fields -->
                    <tr>
                        <td><input type="text" name="doc1" /></td>
                        <td><input type="text" name="rev1" /></td>
                        <td><input type="text" name="eff1" /></td>
                        <td><input type="text" name="taskNumber1" /></td>
                    </tr>
                    <tr>
                        <td><input type="text" name="doc2" /></td>
                        <td><input type="text" name="rev2" /></td>
                        <td><input type="text" name="eff2" /></td>
                        <td><input type="text" name="taskNumber2" /></td>
                    </tr>
                    <tr>
                        <td><input type="text" name="doc3" /></td>
                        <td><input type="text" name="rev3" /></td>
                        <td><input type="text" name="eff3" /></td>
                        <td><input type="text" name="taskNumber3" /></td>
                    </tr>
                </tbody>
            </table>
            <button @click="closeModal" class="closebtn">Close</button>

        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            showDocModal: true,
        };
    },
    props: {
        showModal: Boolean
    },
    methods: {
        closeModal() {
            this.$emit('update:showModal', false);
        }
    }
}
</script>
  
<style scoped>
.doc-modal-overlay {
    position: absolute;
    padding-top: 1%;
    left: 0;
    background-color: rgba(97, 94, 94, 0.5);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.doc-modal-content {
    background-color: #ffffff;
    max-height: 650px;
    width: 1000px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    overflow: auto;
}

.doc,
.rev,
.eff,
.taskno {
    width: 25%;
    border: 1px solid black;

}

td {
    height: 10px;
        border: 1px solid black
}

.closebtn {
    border-radius: 5px;
    width: 200px;
    background-color: #a52c0d;
    color: white;
    margin-right: 20px;
    margin-top: 5%;
}
</style>
  