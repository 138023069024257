<template>
    <div class="Task-modal-overlay" @click="close">
        <div class="Task-modal-content">
                <div class="Task-modal-header">
        <div class="Task-modal-dialog" @click.stop>
                    <h5 class="Task-modal-title">Task ID: {{ taskId }}</h5>
                </div>   
            </div>  
        <table class="data-table">
        <tbody>
          <tr>
            <td class="fieldset-column"><strong>Task ID:</strong></td>
            <td>{{ taskData.task_id  }}</td>
          </tr>
          <tr>
            <td class="fieldset-column"><strong>Task No:</strong></td>
            <td>{{ taskData.task_no  }}</td>
          </tr>
          <tr>
            <td><strong>Chapter No:</strong></td>
            <td>{{ taskData.chapt_no }}</td>
          </tr>
          <tr>
            <td><strong>Module:</strong></td>
            <td>{{ taskData.module }}</td>
          </tr>
          <tr>
            <td><strong>Location:</strong></td>
            <td>{{ taskData.location }}</td>
          </tr>
          <tr>
            <td><strong>Area:</strong></td>
            <td>{{ taskData.area }}</td>
          </tr>
          <tr>
            <td><strong>AMTOSS:</strong></td>
            <td>{{ taskData.AMTOSS }}</td>
          </tr>
          <tr>
            <td><strong>Mandatory / Obligatory:</strong></td>
            <td> M </td>
          </tr>
          <tr>
            <td><strong>Activity:</strong></td>
            <td>{{ taskData.activity }}</td>
          </tr>
          <tr>
            <td><strong>Examinable:</strong></td>
        <td>{{ taskData.is_exam ? 'True' : 'False' }}</td>
          </tr>
          <tr>
            <td><strong>Product ID:</strong></td>
            <td>{{ taskData.prod_id }}</td>
          </tr>
          <tr>
            <td><strong>Category:</strong></td>
            <td>{{ taskData.category }}</td>
          </tr>
          <tr>
            <td><strong>Level:</strong></td>
            <td>{{ taskData.level }}</td>
          </tr>
          <tr>
            <td><strong>Tail No:</strong></td>
            <td>{{ entryData.ac_reg }}</td>
          </tr>
          <tr>
            <td><strong>Jobcard No:</strong></td>
            <td>{{ entryData.jobcard }}</td>
          </tr>
          <tr>
            <td><strong>AMM Reference:</strong></td>
            <td>{{ entryData.amm_ref }}</td>
          </tr>
          <tr>
            <td><strong>Proof Data:</strong></td>
            <td>{{ entryData.proof_data }}</td>
          </tr>
          <tr>
            <td><strong>Enabled:</strong></td>
            <td>{{ entryData.enabled }}</td>
          </tr>
          <tr>
            <td><strong>Completed:</strong></td>
            <td>{{ entryData.completed }}</td>
          </tr>
        </tbody>
      </table>
    </div>
        </div>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            showTaskLongFormModal: true,
            taskData: [],
            entryData: [],
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },  
        async getTaskData(taskId) {
            const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
            console.log(taskId);
            try {
                // Assuming you have an API endpoint to fetch task data
                const response = await axios.get(`/api/tasks/${taskId}`, { headers });
                const data = response.data;
                this.taskData = data;
                console.log(data);
               
            } catch (error) {
                console.error('Error fetching task data:', error);
                // Handle errors as needed
            }
        },
        async getEntryData(taskId, logbookId) {
            const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
            console.log(taskId);
            try {
                // Assuming you have an API endpoint to fetch task data
                const response = await axios.get(`/api/logbookentry/${logbookId}/${taskId}`, { headers });
                const data = response.data.task;
                this.entryData = data;
                console.log(data);
               
            } catch (error) {
                console.error('Error fetching task data:', error);
                // Handle errors as needed
            }
        },
    },
    async mounted() {
    this.getTaskData(this.taskId);
    this.getEntryData(this.taskId, this.logbookId);
  },
}
</script>
<style scoped>
.Task-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(146, 146, 146, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.Task-modal-content {
    margin-top: 40px;
    background-color: rgb(239, 244, 255);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    max-width: 50%; /* You can adjust the maximum width as needed */
    max-height: 87vh; /* You can adjust the maximum height as needed */
    overflow-y: auto;
    border: 2px solid rgb(83, 83, 83);
    margin-top: 80px;
}
.Task-modal-title {
    margin-bottom: 10px;
    float: left;
    margin-left: 20px;
    color: black;
}
.data-table {
    border-collapse: collapse;
    background-color: rgb(253, 253, 255);
    border: 1px solid grey;
    box-shadow: 0;
}
.data-table td, .data-table th {
    border: none;
    padding: 8px;
    width: 250px;
}
</style>