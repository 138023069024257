<template>
    <div v-if="showDocModal" class="doc-modal-overlay">
        <div class="doc-modal-content">
            <h3>Designated Document References</h3>
            <!-- <h6 style="text-align: center; font-weight: bold;">EXAMPLES</h6> -->
            <table>
                <thead style="text-align: left; border: 1px solid black">
                    <tr style="text-align: left; border: 1px solid black">
                    <th style="background-color:rgba(60, 132, 241, 0.342); color: white;  border: 1px solid black">Doc.</th>
                    <th style="background-color:rgba(60, 132, 241, 0.342); color: white;">Task Number</th>
                    </tr>
                </thead>
                <tbody style="text-align: left;">
                    <tr>
                        <td>FIM</td>
                        <td>Fault Isolation Yaw Damper</td>
                    </tr>
                    <tr>
                        <td>FIM</td>
                        <td>Yaw Damper Upper Light Illuminated (Page 204)</td>
                    </tr>
                    <tr>
                        <td>MEL</td>
                        <td>MEL C-Item: May affect CAT II/III capability!</td>
                    </tr>
                    <tr>
                        <td>AMM</td>
                        <td>YD Computer components locations</td>
                    </tr>
                    <tr>
                        <td>AMM</td>
                        <td>Page 401: Yaw Damper computer removal (Step 3)</td>
                    </tr>
                    <tr>
                        <td>AMM</td>
                        <td>Page 401: Yaw Damper computer installation (Step 4)</td>
                    </tr>
                    <tr>
                        <td>AMM</td>
                        <td>Page 401: Yaw Damper computer test (Step A)</td>
                    </tr>
                </tbody>
            </table>

            <table style="margin-top:50px;">
                <thead style="text-align: left;">
                    <tr style="background-color:rgba(60, 132, 241, 0.342);">
                        <th class="doc">Doc.</th>
                        <th class="rev">Rev.</th>
                        <th class="eff">Eff.</th>
                        <th class="taskno">Task Number</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Example entries from the image -->
                    <tr>
                        <td><input type="text" name="doc" value="MEL" /></td>
                        <td><input type="text" name="rev" value="AUG15 / 081" /></td>
                        <td><input type="text" name="eff" value="223" /></td>
                        <td><input type="text" name="taskNumber" value="Fuel Gauge Check" /></td>
                    </tr>
                    <!-- Empty rows with input fields -->
                    <tr>
                        <td><input type="text" name="doc1" /></td>
                        <td><input type="text" name="rev1" /></td>
                        <td><input type="text" name="eff1" /></td>
                        <td><input type="text" name="taskNumber1" /></td>
                    </tr>
                    <tr>
                        <td><input type="text" name="doc2" /></td>
                        <td><input type="text" name="rev2" /></td>
                        <td><input type="text" name="eff2" /></td>
                        <td><input type="text" name="taskNumber2" /></td>
                    </tr>
                    <tr>
                        <td><input type="text" name="doc3" /></td>
                        <td><input type="text" name="rev3" /></td>
                        <td><input type="text" name="eff3" /></td>
                        <td><input type="text" name="taskNumber3" /></td>
                    </tr>
                </tbody>
            </table>
            <button @click="closeModal" class="closebtn">Close</button>

        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            showDocModal: true,
        };
    },
    props: {
        showModal: Boolean
    },
    methods: {
        closeModal() {
            this.$emit('update:showModal', false);
        }
    }
}
</script>
  
<style scoped>
.doc-modal-overlay {
    position: absolute;
    padding-top: 1%;
    left: 0;
    background-color: rgba(97, 94, 94, 0.5);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.doc-modal-content {
    background-color: #ffffff;
    max-height: 800px;
    width: 1000px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    overflow: auto;
}

.doc,
.rev,
.eff,
.taskno {
    width: 25%;

    border: 1px solid black;
}


td {
    height: 10px;
    border: 1px solid black
}


.closebtn {
    border-radius: 5px;
    width: 200px;
    background-color: #a52c0d;
    color: white;
    margin-right: 20px;
    margin-top: 5%;
}
</style>
  