<template>
    <div class="modal-overlay" @click="closeModal">
      <div class="products-modal" @click.stop>
          <div class="modal-content" @click.stop>
<div id="app" class="bg-secondary">
  <div class="row bg-secondary" style="margin-right: 0px; margin-left: 0px">
    <div class="col-md bg-secondary">
      <div class="card">
        <div class="card-body" style="background: darkgray">
          <div>
            <!-- we used vueSignature , so write in form vueSignature -->
            <qrcode-stream :track="drawOutline"></qrcode-stream>
        </div>
          <button @click="closeModal" variant="info" class="m-1">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
  </div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader';

export default {
    name: 'QrcodeModal',
    components: {
        QrcodeStream,
    },
    data() {
        return {
            showQRCode: true,
        };
    },

    methods: {
        closeModal() {
            this.$emit('close');
        }, 
        drawOutline(decodeData, context) {
          var points = []

          for (var k in decodeData[0].cornerPoints) 
          {
            switch(k) {
              case "0":
                points[0] = decodeData[0].cornerPoints[k] 
                break;

              case "1":
                points[1] = decodeData[0].cornerPoints[k] 
                break;

                case "2":
                points[2] = decodeData[0].cornerPoints[k] 
                break;

                case "3":
                points[3] = decodeData[0].cornerPoints[k] 
                break;

              default:
                break
            }
          }
          context.lineWidth = 10;
          context.strokeStyle = 'green';
            context.beginPath();

            context.moveTo(points[0].x, points[0].y);

            for(const {x, y} of points) {
              context.lineTo(x, y);
            }

            context.lineTo(points[0].x, points[0].y);
            context.closePath();
            context.stroke();


          console.log(points);
          console.log(context);
          console.log(decodeData[0].rawValue);
        } 
    },
    created() {
    console.log('Component created');
},
mounted() {
    console.log('Component mounted');
},
}


</script>

<style scoped>

button.m-1:hover {
  background-color: rgb(224, 224, 224);
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.products-modal {
    background-color: #fff;
    max-width: 90%;
    min-height: 50%;
    min-width: 60%;
    max-height: 90vh;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: block;
    z-index: 10000;
    margin-top: 60px;
}

.modal-content {
    max-height: 80vh;
    overflow-y: auto;
}
</style>