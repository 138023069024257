<template>
  <div class="popup-overlay" @click.self="close">
    <div class="popup">
      <h5>Progress</h5>
      <table>
        <tbody>  
          <tr>
            <td>Total Tasks:</td>
            <td>{{ totalTasks }}</td>
          </tr>
          <tr>
            <td>Completed:</td>
            <td>{{ completedTasks }}</td>
          </tr>
          <tr>
            <td>Enabled:</td>
            <td>{{ enabledTasks }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    completedTasks: Number,
    totalTasks: Number,
    enabledTasks: Number,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
  
<style scoped>
.popup-overlay {
  position: absolute;
  padding-top: 1%;
  left: 0;
  background-color: rgba(97, 94, 94, 0.5);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.popup {
  background-color: #ffffff;
  max-height: 150px;
  width: 200px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  overflow: auto;
}
</style>
  