<template>
    <div>

    </div>
</template>  
<script>
import { jsPDF } from "jspdf";
import "jspdf-autotable";
//import axios from "axios";

export default {
    methods: {
        async generatePDF(tasks, logbookName) {
            //eslint-disable-next-line
            debugger;
            this.traineeName = localStorage.getItem('traineeName');
            this.instructorName = localStorage.getItem('instructorName');
            this.traineeId = localStorage.getItem('trainee_id');
            this.instructorId = localStorage.getItem('user_id');
            this.productName = localStorage.getItem('productName');

            const doc = new jsPDF();

            // Add Logbook Name as heading
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(16);
            doc.text(logbookName, 10, 10);

            // Grey outlined box
            const boxStartY = 15;
            const boxHeight = 30;
            doc.setDrawColor(200); // Gray outline
            doc.setFillColor(240, 240, 240); // Light gray fill
            doc.rect(10, boxStartY, 190, boxHeight, 'FD'); // Filled box

            doc.setFont('helvetica', 'normal');
            doc.setFontSize(10);
            // Add Trainee and Instructor details within the box
            // Product Name title
            doc.setFont('helvetica', 'bold');
            doc.text("Product Name:", 12, 23);

            // Product Name content
            doc.setFont('helvetica', 'normal');
            doc.text(this.productName, 50, 23);

            // Instructor title
            doc.setFont('helvetica', 'bold');
            doc.text("Instructor:", 12, 31);

            // Instructor content
            doc.setFont('helvetica', 'normal');
            doc.text(this.instructorName, 50, 31);

            // Instructor ID title
            doc.setFont('helvetica', 'bold');
            doc.text("Instructor ID:", 80, 31);

            // Instructor ID content
            doc.setFont('helvetica', 'normal');
            doc.text(this.instructorId, 110, 31);

            // Trainee title
            doc.setFont('helvetica', 'bold');
            doc.text("Trainee:", 12, 38);

            // Trainee content
            doc.setFont('helvetica', 'normal');
            doc.text(this.traineeName, 50, 38);

            // Trainee ID title
            doc.setFont('helvetica', 'bold');
            doc.text("Trainee ID:", 80, 38);

            // Trainee ID content
            doc.setFont('helvetica', 'normal');
            doc.text(this.traineeId, 110, 38);

            doc.setFont('helvetica', 'bold');
            doc.setFontSize(12);
            const columns = ['Task No', 'module', 'chapt_no', 'location', 'AMTOSS', 'category', 'level', 'activity', 'completed', 'ins sign off'];
            const filteredTasks = tasks.filter(task => task.completed && task.completed.trim() !== "");

            const rows = filteredTasks.map(task => [
                task.task_no,
                task.module,
                task.chapt_no,
                task.location,
                task.AMTOSS,
                task.category,
                task.level,
                task.activity,
                task.completed,
                task.ins_so,
            ]);

            doc.autoTable({
                head: [columns],  // Array of column headers
                body: rows,
                startY: boxStartY + boxHeight + 5, // start below the box with a small gap
                styles: { overflow: 'linebreak' },
                headStyles: {
                    fontSize: 6  // Adjust font size for the column headers
                },
                bodyStyles: {
                    fontSize: 7  // font size for the table body data
                },
                columnStyles: { text: { cellWidth: '5px' } },
                margin: { horizontal: 10 }
            });

        /*    try {

                const response = await axios.get(`/api/check-signature?instructorId=${this.instructorId}`);
                if (response.data.isSigned) {
                    const timestamp = response.data.timestamp;
                    // Add the digital signature text to the PDF
                    doc.setFont('helvetica', 'italic');
                    doc.setFontSize(12);
                    doc.setTextColor(100);
                    doc.text(`Signed digitally by ${this.instructorName} on ${timestamp}`, 10, doc.internal.pageSize.height - 10);
                }
            } catch (error) {
                console.error('Error checking digital signature:', error);
                // Handle error (e.g., show an alert or log it)
            }*/
            
            const blob = doc.output('blob');

            // Create an Object URL from the blob and open it in a new tab
            const pdfURL = URL.createObjectURL(blob);
           
            window.open(pdfURL, '_blank');
        }

    }
}
</script>

  
  
<style scoped>
h1.page-title {
    text-align: left;
    padding-top: 100px;
    font-size: 22px;
    margin-left: 3%;
    padding-bottom: 10px;
}
</style>
